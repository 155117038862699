const todos = (state = [], action) => {
    switch (action.type) {
        case 'ADD_TODO':
            return {
                ...state,
                data: action.data,
            }
        case 'RESET_TODO':
            return {
                ...state,
                data: null,
            }
        default:
            return state
    }
}
export default todos