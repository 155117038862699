import { createStore, applyMiddleware, combineReducers } from 'redux'
import sampleReducer from './services/reducers/Sample'
import authenReducer from './services/reducers/Authen'
import ReduxThunk from 'redux-thunk'

const initialState = {
  sidebarShow: 'responsive',
  collapsed: false
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'MENU_SIDE_BAR_CLICK':
      return {
        ...state,
        collapsed: true,
      }
    case 'TOGGLE_SIDE_BAR':
      return {
        ...state,
        ...rest
      }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  sampleReducer,
  authenReducer,
  changeState
})

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));
export default store